<template>

  <v-row v-if="isLoading">
    <v-col cols="6" lg="3" v-for="item in 4" :key="item">
      <v-card class="rounded-lg shadow">
        <v-card-text>
          <v-skeleton-loader type="text,text"></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <div v-else>

    <v-row v-if="tab === 'one'">
      <v-col cols="12" lg="3">
        <v-card class="shadow rounded-lg">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col>
                <h3> {{ stats.total }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="gifty" size="45">
                  <v-icon dark>mdi-percent</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between align-center">
              <span class="text-no-wrap">Nombre total</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="3">
        <v-card class="shadow rounded-lg">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col>
                <h3>{{ stats.total_validated }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="success" size="45">
                  <v-icon dark>mdi-check</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between align-center">
              <span class="text-no-wrap">Validées</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="3">
        <v-card class="shadow rounded-lg">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col>
                <h3>{{ stats.total_active }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="info" size="45">
                  <v-icon dark>mdi-check</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between align-center">
              <span class="text-no-wrap">Actives</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="3">
        <v-card class="shadow rounded-lg">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col>
                <h3>{{ stats.total_pending }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="orange" size="45">
                  <v-icon dark>mdi-clock</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between align-center">
              <span class="text-no-wrap">En attente</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row  v-if="tab === 'two'">
      <v-col cols="12" lg="6">
        <v-card class="shadow rounded-lg">

          <v-card-title>
            Taux de visibilité
          </v-card-title>

          <v-card-text class="align-start mt-2">
            <DoughnutChart :labels="[
                'Publicités Vu',
                'Non vu'
                 ]"
                           :data="[
                stats.total_seen,
                stats.total_active  - stats.total_seen
              ]"
                           cutout="0"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="6">
        <v-card class="shadow rounded-lg">

          <v-card-title>
            Taux d'interaction
          </v-card-title>

          <v-card-text class="align-start mt-2">
            <DoughnutChart
                :labels="[
                'Publicités visité',
                'Non visité'
              ]"
                :data="[
                stats.total_clicked,
                stats.total_seen - stats.total_clicked
              ]"
                cutout="0"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

import DoughnutChart from "@/components/charts/DoughnutChart.vue";

export default {
  props: ["filter","tab"],
  components: {DoughnutChart},
  data() {
    return {
      isLoading: false,
      stats: [],
    };
  },

  methods: {
    fetchData() {
      this.isLoading = true;
      let url = "/V2/publicities/statistiques";

      HTTP.get(url, {
        params: {
          ...this.filter,
        },
      })
          .then((res) => {
            this.isLoading = false;
            this.stats = res.data;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>