<template>
  <v-skeleton-loader type="article" v-if="hoursGraphLoading"/>

  <v-card class="shadow rounded-lg" v-else>
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text>
      <Bar :labels="hoursGraph.times"
           :data="hoursGraph.values"
           :title="title"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {HTTP} from "@/http-common";
import Bar from "@/components/charts/Bar.vue";

export default {
  props: ["axis", "publicity", "title"],
  components: {Bar},
  data() {
    return {
      hoursGraphLoading: false,
      hoursGraph: [],
    };
  },

  methods: {
    fetchData() {
      this.hoursGraphLoading = true;
      let url = null;
      if (this.publicity != null) {
        url = "/V2/publicities/interations?publicity=" + this.$route.params.id;
      } else {
        url = "/V2/publicities/interations?";
      }

      if (this.axis != null) {
        url += "&&statsBy=" + this.axis;
      }

      HTTP.get(url)
          .then((res) => {
            this.hoursGraphLoading = false;
            this.hoursGraph = res.data;
          })
          .catch((err) => {
            this.hoursGraphLoading = false;
            console.log(err);
          });
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>